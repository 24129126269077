export enum RoomsActionTypes 
{
  // - room add fetch
  ROOM_ADD_FETCH = 'rooms/ROOM_ADD_FETCH',
  ROOM_ADD_FETCH_FAIL = 'rooms/ROOM_ADD_FETCH_FAIL',
  ROOM_ADD_FETCH_SUCCESS = 'rooms/ROOM_ADD_FETCH_SUCCESS',
  
  // - room get fetch
  ROOM_GET_FETCH = 'rooms/ROOM_GET_FETCH',
  ROOM_GET_FETCH_FAIL = 'rooms/ROOM_GET_FETCH_FAIL',
  ROOM_GET_FETCH_SUCCESS = 'rooms/ROOM_GET_FETCH_SUCCESS',

  // - join room fetch
  ROOM_JOIN_ROOM_FETCH = 'rooms/ROOM_JOIN_ROOM_FETCH',
  ROOM_JOIN_ROOM_FETCH_FAIL = 'rooms/ROOM_JOIN_ROOM_FETCH_FAIL',
  ROOM_JOIN_ROOM_FETCH_SUCCESS = 'rooms/ROOM_JOIN_ROOM_FETCH_SUCCESS',
  
  // - rooms get fetch
  ROOMS_GET_FETCH = 'rooms/ROOMS_GET_FETCH',
  ROOMS_GET_FETCH_FAIL = 'rooms/ROOMS_GET_FETCH_FAIL',
  ROOMS_GET_FETCH_SUCCESS = 'rooms/ROOMS_GET_FETCH_SUCCESS',

  // - rooms start draft fetch
  ROOMS_START_DRAFT_FETCH = 'rooms/ROOMS_START_DRAFT_FETCH',
  ROOMS_START_DRAFT_FETCH_FAIL = 'rooms/ROOMS_START_DRAFT_FETCH_FAIL',
  ROOMS_START_DRAFT_FETCH_SUCCESS = 'rooms/ROOMS_START_DRAFT_FETCH_SUCCESS',

  // - rooms start sealed fetch
  ROOMS_START_SEALED_FETCH = 'rooms/ROOMS_START_SEALED_FETCH',
  ROOMS_START_SEALED_FETCH_FAIL = 'rooms/ROOMS_START_SEALED_FETCH_FAIL',
  ROOMS_START_SEALED_FETCH_FETCH_SUCCESS = 'rooms/ROOMS_START_SEALED_FETCH_FETCH_SUCCESS',

  // - rooms make picks fetch
  ROOMS_MAKE_PICKS_FETCH = 'rooms/ROOMS_MAKE_PICKS_FETCH',
  ROOMS_MAKE_PICKS_FETCH_FAIL = 'rooms/ROOMS_MAKE_PICKS_FETCH_FAIL',
  ROOMS_MAKE_PICKS_FETCH_SUCCESS = 'rooms/ROOMS_MAKE_PICKS_FETCH_SUCCESS',

  // - rooms next round fetch
  ROOMS_NEXT_ROUND_FETCH = 'rooms/ROOMS_NEXT_ROUND_FETCH',
  ROOMS_ROOMS_NEXT_ROUND_FETCH_FETCH_FAIL = 'rooms/ROOMS_ROOMS_NEXT_ROUND_FETCH_FAIL',
  ROOMS_ROOMS_NEXT_ROUND_FETCH_SUCCESS = 'rooms/ROOMS_ROOMS_NEXT_ROUND_FETCH_SUCCESS',

  // - rooms start draft fetch
  ROOMS_CLEAR_ROOM_INFO = 'rooms/ROOMS_CLEAR_ROOM_INFO',
}